<template>
  <div :class="prefixCls">
    <div :class="`${prefixCls}-carousel`">
      <Carousel :carouselData="swipeList" height="600px">
      </Carousel>
    </div>
    <main :class="`${prefixCls}-content`">
      <aside :class="`${prefixCls}-navs`">
        <div :class="`${prefixCls}-navs-item ${active === '/manpower/person'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('/manpower/person')">
          人才理念
        </div>
        <div :class="`${prefixCls}-navs-item ${active === '/manpower/invite'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('/manpower/invite')">
          招贤纳士
        </div>
        <div :class="`${prefixCls}-navs-item ${active === '/manpower/edu'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('/manpower/edu')">
          教育培训
        </div>
      </aside>
      <transition name="custom"
                  mode="out-in"
                  appear>
        <router-view :introductionData="aochenData.companyProfile[0]" :leadershipCare="aochenData.leadershipCare"></router-view>
      </transition>
    </main>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel/index.vue'
import { carouselMixin } from '@/mixin/carousel'
import { getBannerByChannelId } from '@/api'
// import { getAbout } from '@/api/aochen'
export default {
  name: 'Aochen',
  components: {
    Carousel
  },
  data () {
    return {
      prefixCls: this.$prefix + '-company',
      aochenData: {
        companyProfile: []
      },
      active: '',
      transitionName: 'left-move',
      swipeList: []
    }
  },
  mixins: [carouselMixin],
  activated () {
    this.active = this.$route.path
    // getAbout().then((res) => {
    //   this.aochenData = res.data.data
    // })
  },
  watch: {
    $route: function (val) {
      this.active = val.path
      console.log(this.active, 'da')
    }
  },
  created () {
    this.active = this.$route.path
    this.getBannerByChannelId()
  },
  methods: {
    getBannerByChannelId () {
      getBannerByChannelId(7).then(({ data }) => {
        this.swipeList = data.data
      })
    },
    toggleNav (path) {
      if (this.active === path) {
        return false
      }
      this.active = path
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -company;
.#{$prefixCls}{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  padding-bottom: 40px;
  &-carousel{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    overflow: hidden;
  }
  &-content{
    display: flex;
    flex-direction: row;
    width: $content-width;
    min-height: 800px;
  }
  &-navs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 75px;
    margin-top: 50px;
    font-size: 20px;
    &-active{
      background-color: #1E2A48 !important;
      color: #fff !important;
    }
    &-item{
      color: #000;
      text-align: center;
      width: 240px;
      background-color: #F5F7FA;
      height: 80px;
      line-height: 80px;
      cursor: pointer;
    }
  }
}
</style>
